<script>
import axios from 'axios'
import Layout from "../layouts/main";
import PageHeader from "@/components/page-header";
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";
import { required } from "vuelidate/lib/validators";
import fileDownload from 'js-file-download';

export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
  },
  data() {
    return {
      title: "Batch",
      DataFile:[],
      id_data: '',
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      fields: [
        { key: "create_at", label:'Date' },
        { key: "filename", label:'File Name' },
        { key: "status", label:"Status" },
        { key: "approve", label:"Approve" }
      ],
      fieldsModal: [
        { key: "des_country", label:'Country' },
        { key: "name", label:"Name" },
        { key: "sendAmount", label:"Amount" },
        { key: "description", label:'Description' },
        { key: "detail", label:"Detail" },
        { key: "invoice", label:"Invoice" }
      ],
      ADPList:[
        {name: 'ADP1-Purchase Goods and Service'},
        {name: 'ADP2-Savings'},
        {name: 'ADP3-Living Cost'},
        {name: 'ADP4-Education'},
        {name: 'ADP5-Health Services'},
        {name: 'ADP6-Payroll/ Salaries/ Wages'},
        {name: 'ADP7-Donation'},
        {name: 'ADP8-Investment'},
        {name: 'ADP9-Gift'},
        {name: 'ADP10-Others'}
      ],
      ADSList:[
        {name: 'ADS1-Business income'},
        {name: 'ADS2-Saving'},
        {name: 'ADS3-Salary'},
        {name: 'ADS4-Investment'},
        {name: 'ADS5-Gift'},
        {name: 'ADS6-Donation'},
        {name: 'ADS7-Claim / Compensation'},
        {name: 'ADS8-Sales of Assets'},
        {name: 'ADS9-Other'}
      ],
      showmodal:false,
      showmodal1:false,
      showmodal2:false,
      showmodal3:false,
      showmodal4:false,
      DataModal:[],
      isDisabled:false,
      submitted:false,
      dragging: false,
      users: [],
      userID: "",
      file: null,
      show:false,
      filename:null,
      fileLink:null,
      status:null,
      id_file:null,
      destination_country:[],
      des_country:null,
      forms:[],
      bank_list:[],
      purpose:[],
      purposeID:'',
      source:[],
      sourceID:'',
      banklist:[],
      isDisable:false,
      submitted2: false,
      fullname:'',
      data_rec:[],
      screenshotview:'',
      isBusy : false,
    };
  },
  validations: {
    des_country : {
      required
    },
    // purposeID : {
    //   required
    // },
    // sourceID : {
    //   required
    // },
    banklist : {
      required
    },
  },
  computed: {
    rows() {
      return this.DataFile.length;
    },
    extension() {
      return (this.file) ? this.file.name.split('.').pop() : '';
    }
  },
  methods: {
    batch(){
      this.isBusy = true;
      let id = JSON.parse(localStorage.getItem("user")).user_id;
      axios.get(`v1/get_Batch/${id}`)
      .then((res) => {
        this.isBusy = false;
        this.DataFile = res.data.files
        this.DataFile.forEach(function(element) {
            element.filename = `${element.file.split('/')[6]}`
        })
        if(!JSON.parse(localStorage.getItem("user")).id){
            let Item = { key : 'admin_name'}
            this.fields.splice(3,0,Item)
          }
      })
    },
    getSenderbyCountry() {
      let id = JSON.parse(localStorage.getItem("user")).user_id;
      axios.get(`v1/get-sender/${id}`).then((res) => {
        this.users = res.data.user;
        this.users.forEach(function(element) {
          element.item = `${element.sender_name} - ${element.idcard_num}`;
        });
      });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    formatPriceRP(value) {
      let val = (value / 1).toFixed(0).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    formatPriceVND(value) {
      let val = (value / 1).toFixed(3).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    detail(e){
      let data = e.item.id;
      this.id_file = data;
      this.filename = e.item.filename;
      this.fileLink = e.item.file;
      this.status = e.item.status;
      let name = this.users.find((
        (x) => x.user_id == e.item.send_id
      ))
      this.fullname = name['sender_name']
      this.showmodal = true;
      this.getData(data);
    },
    details(e){
      this.showmodal3 = true;
      this.data_rec = e.item
    },
    getData(e){
      axios.get(`v1/gets_Batch/${e}`)
      .then((res) => {
        this.DataModal = res.data.files
        this.isDisabled = false;
        for(var i = 0; i < this.DataModal.length; i++) {
          if(this.DataModal[i].inv == null && this.DataModal[i].des_country == 'USD Worldwide') {
            this.DataModal[i].invalid = 'Upload Invoice is Required'
          }
          if(this.DataModal[i].inv == null && Number(this.DataModal[i].send)+Number(this.DataModal[i].fee) >= '100000000') {
            this.DataModal[i].invalid = 'Upload Invoice is Required'
          }
          if (this.DataModal[i].keterangan !== '') {
            this.isDisabled = true;
          } 
          if (this.DataModal[i].invalid) {
            this.isDisabled = true;
          }
        }
      })
    },
    hideModal() {
      this.showmodal = false;
    },
    hideModal1() {
      this.showmodal1 = false;
      this.des_country = ''
      this.sourceID = ''
      this.purposeID = ''
      this.banklist = ''
      this.bank_list = []
    },
    onChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      
      if (!files.length) {
        this.dragging = false;
        return;
      }
      
      this.createFile(files[0]);
    },
    createFile(file) {
      if (!file.type.match('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
        Swal.fire("File Not Xlsx", 'please select Xlsx file', "error")
        this.dragging = false;
        return;
      }
      
      if (file.size > 20000) {
        Swal.fire("File Oversize", 'please check file size no over 20 KB.', "error")
        this.dragging = false;
        return;
      }
      
      this.file = file;
      let data = new FormData()
      data.append('file', file);
      data.append('send_id', this.userID.user_id);
      data.append('admin_id', JSON.parse(localStorage.getItem("user")).id) ? JSON.parse(localStorage.getItem("user")).id : '';
      let id = JSON.parse(localStorage.getItem("user")).user_id;
      axios.post(`/v1/upload-bulk/${id}`,data)
      .then((res) => {
        if(res.data.success){
          this.$bvToast.toast("Upload success", {
            title: `Success`,
            solid: true,
            variant: 'primary',
            toaster:'b-toaster-top-center'
          });
          this.batch();
          this.show = false;
          this.userID = [];
          this.file = '';
        } else {
          this.file = '';
          Swal.fire("Error", res.data.message , "error");
        }
      })
      .catch((err) => {
        if(err){
          Swal.fire("File Error", 'Format file wrong.', "error");
          this.file = '';
        }
      })
      this.dragging = false;
    },
    enables(){
      this.show = true;
    },
    remove(e){
      let id = e.item.id
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-danger ml-2",
          cancelButton: "btn btn-secondary"
        },
        buttonsStyling: false
      });

      swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        showCancelButton: true,
        reverseButtons: true,
      }) 
      .then(result => {
        if (result.value) {
          axios.post(`/v1/del_Batch/${id}`)
          .then((res) => {
            if(res.data.success){
              this.getData(this.id_file);
              swalWithBootstrapButtons.fire(
                "Deleted!",
                "Data has been deleted.",
                "success"
              );
            }
          })
        }
      })
    },
    reject(){
      let id = this.id_file
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-danger ml-2",
          cancelButton: "btn btn-secondary"
        },
        buttonsStyling: false
      });

      swalWithBootstrapButtons
      .fire({
        title: "Reject Transaction ?",
        text: "You won't be able to revert this!",
        icon: "warning",
        confirmButtonText: "Yes, Reject it!",
        cancelButtonText: "No, cancel!",
        showCancelButton: true,
        reverseButtons: true,
      }) 
      .then(result => {
        if (result.value) {
          axios.post(`/v1/reject_Batch/${id}`)
          .then((res) => {
            if(res.data.success){
              this.batch();
              swalWithBootstrapButtons.fire(
                "Rejected!",
                "Transaction has been Rejected.",
                "success"
              );
              this.showmodal = false;
            }
          })
        }
      })
    },
    handleSubmit(){
      let id = this.id_file
      this.isDisabled = true;
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success ml-2",
          cancelButton: "btn btn-secondary"
        },
        buttonsStyling: false
      });

      swalWithBootstrapButtons
      .fire({
        title: "Process Transaction ?",
        text: "Process all transaction in document ?",
        icon: "warning",
        confirmButtonText: "Yes, Process it!",
        cancelButtonText: "No, cancel!",
        showCancelButton: true,
        reverseButtons: true,
      }) 
      .then(result => {
        if (result.value) {
          let data = {
            admin_name : JSON.parse(localStorage.getItem("user")).user_id,
            admin : JSON.parse(localStorage.getItem("user")).id,
          }
          this.submitted = true;
          axios.post(`/v1/process_Batch/${id}`, data)
          .then((res) => {
            if(res.data.success){
              this.batch();
              swalWithBootstrapButtons.fire(
                "Success!",
                "All Transaction has been Processing.",
                "success"
              );
              this.showmodal = false;
              this.submitted = false;
              this.isDisabled = false;
            }
          })
          .catch((err) => {
            if(err) {
              Swal.fire("Something Wrong", 'Please Contact us', "error");
              this.submitted = false;
              this.isDisabled = false;
            }
          })
        } else {
          this.submitted = false;
          this.isDisabled = false;
        }
      })
    },
    download(){
      window.open(this.fileLink);
    },
    getCountry(){
      let id  = JSON.parse(localStorage.getItem("user")).user_id;
        axios.get(`v1/get-rate/${id}`)
        .then((res) => {
            this.destination_country = res.data.data.filter((x) => {
              return x.currency_code == 'MYR' || x.currency_code == 'VND' || x.currency_code == 'PHP' || x.currency_code == 'USD' || x.currency_code == 'USDWW' || x.currency_code == 'THB' || x.currency_code == 'IDR'
            })
        })
    },
    getBanklist2(){
      this.banklist = ''
      axios.get(`v1/get-field/2/${this.des_country.currency_code}`)
        .then((res) => {
          this.forms = res.data.remitter_parameters.recipient_information;
        })
      axios.get(`v1/banklist-vendor/${this.des_country.currency_code}`)
      .then((res) => {
          this.bank_list = res.data.data;
          this.bank_list.forEach(function(element) {
              element.item = `${element.id_bank}-${element.nama_bank}`
          })
      })
    },
    // getPurpose(){
    //   axios.get(`v1/purpose`)
    //   .then((res) => {
    //     this.purpose = res.data.source
    //   })
    // },
    // getSource(){
    //   axios.get(`v1/source`)
    //   .then((res) => {
    //     this.source = res.data.source
    //   })
    // },
    handleDownload(){
      this.$v.des_country.$touch();
      // this.$v.purposeID.$touch();
      // this.$v.sourceID.$touch();
      this.$v.banklist.$touch();
      if(!this.$v.des_country.$invalid 
      // && !this.$v.purposeID.$invalid 
      // && !this.$v.sourceID.$invalid 
      && !this.$v.banklist.$invalid) {
        this.isDisabled = true;
        this.submitted2 = true;
        let bank = this.banklist.map(function(x) {return x.item})
        let data = {
          country: this.des_country.country,
          curr_code: this.des_country.currency_code,
          // purpose : this.purposeID.sort_id,
          // source : this.sourceID.sort_id,
          bank_list : bank
        }
        axios({
          url: `/v1/download_tempt`,data,
          method: 'post',
          responseType: 'blob'
        }).then((res) => {
          fileDownload(res.data, `${this.des_country.country} Template.Xlsx`)
          this.hideModal1();
        }).catch((err) => {
          console.log(err)
        })
      } else {
        Swal.fire("Warning", "Select Required", "error");
        this.isDisabled = false;
        this.submitted2 = false;
      }
    },
    copyToClipboard(e) {
      const url = e.name;
      navigator.clipboard.writeText(url).then(
        () => {
          this.$bvToast.toast("Copy to Clipboard Success", {
            title: `Copied`,
            solid: true,
            variant: "success",
          });
        },
      );
    },
    upload(e){
      document.getElementById('invoice').click();
      this.id_data = e.item.id
    },
    onChangeInvoice(e){
      let id = this.id_data
      let inv = e.target.files[0];
      let data = new FormData()
      data.append("invoice", inv)
      axios.post(`/v1/upload_inv/${id}`, data)
      .then((res) => {
        if(res.data.success){
          this.getData(this.id_file)
          this.$bvToast.toast("Uploaded", {
            title: `Upload`,
            solid: true,
            variant: "success",
          });
          document.getElementById('invoice').value = "";
        } else {
          Swal.fire("Warning!!", res.data.message, "error");
        }
      }).catch((err) => {
        console.log(err)
      });
    },
    view(e){
      this.showmodal4 = true;
      this.screenshotview = e.item.inv
    }
  },
  created () {
    this.batch();
    this.getSenderbyCountry();
    this.getCountry();
    // this.getPurpose();
    // this.getSource();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" />
    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-2 col-sm-12 mb-sm-3">
                <b-button pill variant="primary" class="mb-sm-3" @click="showmodal1 = true">
                    <i class="mdi mdi-download font-size-15"><span class="ml-1">Get Template</span></i>
                </b-button>
                <b-button class=" mb-sm-3" pill variant="secondary" @click="showmodal2 = true">
                    <i class="mdi mdi-eye font-size-11"><span class="ml-1">Purpose & Source Code</span></i>
                </b-button>
              </div>
              <div class="col-xl-4 col-sm-12">
                <div class="card p-2">
                  <div class="card-body p-3">
                    <h3 class="pb-1">Upload</h3>
                      <b-form-group label="Select Sender">
                        <div class="input-group">
                          <multiselect
                            v-model="userID"
                            :options="users"
                            label="item"
                            placeholder="Select Sender"
                            required
                            :selectLabel="null"
                            :deselectLabel="null"
                            :allow-empty="false"
                            @input="enables"
                          >
                          </multiselect>
                        </div>
                      </b-form-group>
                      <div style="line-height:1rem">
                        <li>Make sure all required fields are filled.</li>
                        <li>Date format : yyyy-mm-dd.</li>
                        <li>Do not use thousands separator.</li>
                      </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-sm-12" v-if="show">
                <div class="card p-2">
                  <div class="card-body p-1">
                    <div v-if="!file">
                      <div :class="['dropZone', dragging ? 'dropZone-over' : '']" @dragenter="dragging = true" @dragleave="dragging = false">
                        <div class="dropZone-info" @drag="onChange">
                          <span class="fa fa-cloud-upload dropZone-title"></span>
                          <span class="dropZone-title font-size-15">Drop .XLSX file here or click to upload</span>
                          <div class="dropZone-upload-limit-info">
                            <div>extension : .xlsx</div>
                            <div>max file size: 20 KB</div>
                          </div>
                        </div>
                        <input type="file" @change="onChange">
                      </div>
                    </div>
                    <div v-else class="dropZone-uploaded">
                      <div class="dropZone-uploaded-info">
                        <!-- <span class="dropZone-title">Uploading...</span> -->
                        <b-spinner class="p-0" style="width:75px;height:75px" variant="black" role="status"></b-spinner>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="">
                  <div class="card-body">
                    <div class="row mt-4">
                      <div class="col-sm-12 col-md-6">
                        <div id="tickets-table_length" class="dataTables_length">
                          <label class="d-inline-flex align-items-center">
                            Show&nbsp;
                            <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                          </label>
                        </div>
                      </div>
                    </div>
                    <!-- Table -->
                    <div class="table-responsive mb-0">
                      <b-table
                        :items="DataFile"
                        :fields="fields"
                        striped
                        responsive="sm"
                        :per-page="perPage"
                        :current-page="currentPage"
                        :busy="isBusy"
                        show-empty
                      >
                      <template v-slot:cell(filename)="row">
                          <b-link :href="row.item.file">{{row.item.filename}}</b-link>
                      </template>
                      <template v-slot:cell(status)="row">
                        <div
                        class="badge font-size-15"
                            :class="{'badge-soft-danger': `${row.value}` === 'Rejected',
                                'badge-soft-primary': `${row.value}` === 'Processed',
                                'badge-soft-secondary': `${row.value}` === 'Uploaded'}"
                        >{{row.item.status}}</div>
                      </template>
                      <template v-slot:cell(admin)="row">
                        <div
                        >{{row.item.admin_name}}</div>
                      </template>
                      <template v-slot:cell(approve)="row">
                      <a
                        @click="detail(row)"
                        href="javascript:void(0);"
                        class="text-primary"
                        title="Approve"
                      >
                        <i class="mdi mdi-file-eye" style="font-size:1.7rem"></i>
                      </a>
                      </template>
                      <template #table-busy>
                        <div class="text-center text-danger my-2">
                          <b-spinner class="align-middle mr-2"></b-spinner>
                          <strong>Loading...</strong>
                        </div>
                      </template>
                      <template #empty>
                        <h5 class='empty'>No Transaction Data ...</h5>
                      </template>
                      </b-table>
                    </div>
                    <!-- <template v-else>
                      <h5 class='empty'>No Data ...</h5>
                    </template> -->
                    <div class="row">
                      <div class="col">
                        <div class="dataTables_paginate paging_simple_numbers float-right">
                          <ul class="pagination pagination-rounded mb-0">
                            <!-- pagination -->
                            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
     <!-- Modal -->
    <b-modal
      id="modal-1"
      v-model="showmodal"
      title="Batch"
      title-class="text-dark font-18"
      scrollable
      hide-footer
      centered
      size="xl"
    >
      <form @submit.prevent="handleSubmit">
        <template>
          <div class="card">
            <div class="card-body">
                <div class="text-center">
                    <b-button variant="danger" @click="reject" :disabled="status != 'Uploaded'">Reject</b-button>
                    <button type="submit" class="btn btn-primary ml-3" style="width:5rem" :disabled="isDisabled || status != 'Uploaded' || DataModal.length == 0">
                        <span v-if="submitted"><b-spinner class="p-0" style="width:20px;height:20px" variant="black" role="status"></b-spinner></span>
                        <span v-else>Process</span>
                    </button>
                    <!-- <b-button variant="secondary ml-3" @click="hideModal">Close</b-button> -->
                </div>
                <div>
                    <h5>File Name : <a href="javascript:void(0);" @click="download">{{filename}}</a></h5>
                    <h5>Sender Name : {{fullname ? fullname.toUpperCase() : '-'}}</h5>
                    <h5>Status : <span :class="status == 'Uploaded' ? 'text-secondary' : status == 'Processed' ? 'text-primary' : 'text-danger'">{{status}}</span></h5>
                </div><hr>
                <div class="row mb-2">
                    <div class="table-responsive mb-0">
                        <b-table
                            :items="DataModal"
                            :fields="fieldsModal"
                            striped
                            responsive="sm"
                            sticky-header
                        >
                        <template v-slot:cell(name)="row">
                          <div style="text-transform: uppercase;"
                          >{{row.item.company_name}}{{row.item.f_name}} {{row.item.l_name}}</div>
                        </template>
                        <template v-slot:cell(sendAmount)="row">
                          <div
                          >{{row.item.recipient_gets %1 != 0 ? row.item.recipient_gets.includes('Send is') ? row.item.recipient_gets : formatPrice(row.item.recipient_gets) : formatPriceRP(row.item.recipient_gets)}}</div>
                        </template>
                        <template v-slot:cell(description)="row">
                          <div class="text-danger"
                          >{{row.item.keterangan ? row.item.keterangan : row.item.invalid}}</div>
                        </template>
                        <template v-slot:cell(detail)="row">
                            <a  
                                @click="details(row)"
                                href="javascript:void(0);"
                                v-b-tooltip.hover
                                title="detail"
                                class="text-primary"
                            >
                            <i class="mdi mdi-eye text-primary mr-1" style="font-size:1.7rem"></i>
                            </a>
                            <a  
                                @click="remove(row)"
                                href="javascript:void(0);"
                                v-b-tooltip.hover
                                title="Remove"
                                :class="status == 'Uploaded' ? 'text-danger' : 'text-danger disabled'"
                            >
                            <i class="mdi mdi-trash-can" :style="status == 'Uploaded' ? 'font-size:1.7rem' : 'font-size:1.7rem;color:#ff8198;'"></i>
                            </a>
                        </template>
                        <template v-slot:cell(invoice)="row">
                            <a  
                                @click="upload(row)"
                                href="javascript:void(0);"
                                v-b-tooltip.hover
                                title="Upload"
                                 :class="row.item.keterangan ? 'text-secondary disabled' : status == 'Uploaded' ? 'text-primary' : 'text-secondary disabled'"
                            >
                            <i class="mdi mdi-upload mr-1" :style="status == 'Uploaded' ? 'font-size:1.7rem' : 'font-size:1.7rem;color:gray;'"></i>
                            <input
                              id="invoice"
                              type="file"
                              @input="onChangeInvoice"
                              hidden
                            />
                            </a>
                            <a  
                                v-if="row.item.inv !== null"
                                @click="view(row)"
                                href="javascript:void(0);"
                                v-b-tooltip.hover
                                title="View"
                                class="text-success"
                            >
                            <i class="mdi mdi-file" style="font-size:1.7rem"></i>
                            </a>
                        </template>
                        </b-table>
                    </div>
                </div>
            </div>
          </div>
        </template>
      </form>
    </b-modal>
    <!-- end modal -->
     <!-- Modal -->
    <b-modal
      id="modal-2"
      v-model="showmodal1"
      title="Get Template"
      title-class="text-dark font-18"
      scrollable
      size="lg"
      @show="hideModal1"
      @hidden="hideModal1"
    >
      <form>
        <template>
          <div class="card" style="min-height:40rem">
            <div class="card-body">
              <div class="form-group row px-3">
                <label>Destination Country</label>
                <multiselect 
                    v-model="des_country" 
                    :options="destination_country"
                    label="country"
                    :selectLabel="null"
                    :deselectLabel="null"
                    :allow-empty="false"
                    placeholder="Select Destination Country"
                    @input="getBanklist2"
                    required
                >
                <template slot="singleLabel" slot-scope="{ option }"><img :src="option.flag" width="25" height="25"><span> {{ option.country }}</span></template>
                <template slot="option" slot-scope="{ option }"><img :src="option.flag" width="25" height="25"><span> {{ option.country }}</span></template>
                </multiselect>
                <small
                  class="mb-0 text-danger"
                  v-if="
                    !$v.des_country.required &&
                    $v.des_country.$error
                  "
                >
                  Select Destination Country is required
                </small>
              </div>
              <!-- <div class="form-group row px-3">
                <label>Select Purpose</label>
                <multiselect
                  v-model="purposeID" 
                  :options="purpose" 
                  :selectLabel="null"
                  :deselectLabel="null"
                  label="name_en"
                  placeholder="Select Purpose of Transfer"
                  :allow-empty="false"
                  required
                >
                </multiselect>
                <small
                  class="mb-0 text-danger"
                  v-if="
                    !$v.purposeID.required &&
                    $v.purposeID.$error
                  "
                >
                  Select Purpose of Transfer is required
                </small>
              </div>
              <div class="form-group row px-3">
                <label>Select Source</label>
                <multiselect
                  v-model="sourceID" 
                  :options="source" 
                  label="name_en"
                  placeholder="Select Source of Fund"
                  required
                  :selectLabel="null"
                  :deselectLabel="null"
                  :allow-empty="false"
                >
                </multiselect>
                <small
                  class="mb-0 text-danger"
                  v-if="
                    !$v.sourceID.required &&
                    $v.sourceID.$error
                  "
                >
                  Select Source of Fund is required
                </small>
              </div> -->
              <div class="form-group row px-3" v-if="des_country">
                <label>{{des_country.currency_code !== 'USDWW' ? 'Select Bank List' : 'Select Country List'}} <small style="color:red">(can select multiple)</small></label>
                <multiselect
                  v-model="banklist" 
                  :options="bank_list" 
                  label="nama_bank"
                  track-by="nama_bank"
                  placeholder="Select Bank Name"
                  :multiple="true"
                  :close-on-select="false"
                >
                </multiselect>
                <small
                  class="mb-0 text-danger"
                  v-if="
                    !$v.banklist.required &&
                    $v.banklist.$error
                  "
                >
                {{des_country.currency_code !== 'USDWW' ? 'Select Bank List is required' : 'Select Country List is required'}}
                </small>
              </div>
              <div class="form-group row px-3" v-else>
                <label>Select Bank List</label>
                <multiselect
                  v-model="banklist" 
                  :options="bank_list" 
                  label="nama_bank"
                  track-by="nama_bank"
                  placeholder="Select Bank Name"
                  :multiple="true"
                  :close-on-select="false"
                >
                </multiselect>
              </div>
            </div>
          </div>
        </template>
      </form>
          <template #modal-footer>
            <b-button size="md" variant="secondary" @click="hideModal1">
              Cancel
            </b-button>
            <b-button size="md" variant="success" @click="handleDownload">
              Download
            </b-button>
          </template>
    </b-modal>
    <!-- end modal -->
     <!-- Modal -->
    <b-modal
      id="modal-2"
      v-model="showmodal2"
      title="Purpose & Source Code"
      title-class="text-dark font-18"
      scrollable
      hide-footer
      centered
      size="lg"
    >
      <form>
        <template>
          <div class="row">
            <div class="col-6">
              <div class="card p-4">
                <div class="table-responsive table-info table-hover">
                  <table class="table">
                    <thead class="bg-light">
                      <tr>
                        <th>Purpose of Transfer Code<small style="color:red"> (Click to Copy)</small></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(data, index) in ADPList" :key="index">
                        <td style="cursor:copy" @click="copyToClipboard(data)">{{data.name}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="card p-4">
                <div class="table-responsive table-info table-hover">
                  <table class="table">
                    <thead class="bg-light">
                      <tr>
                        <th>Source of Fund Code<small style="color:red"> (Click to Copy)</small></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(data, index) in ADSList" :key="index">
                        <td style="cursor:copy" @click="copyToClipboard(data)">{{data.name}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </template>
      </form>
    </b-modal>
    <b-modal
      id="modal-1"
      v-model="showmodal3"
      title="Recipient Details"
      title-class="text-dark font-18"
      hide-footer
      scrollable
      centered
    >
      <form>
        <template>
          <div class="table-responsive table-striped">
            <table class="table mb-0">
              <tbody style="text-transform: uppercase;">
                <tr v-if="data_rec.rec_type == 1">
                  <th scope="row">Full Name</th>
                  <td :class="data_rec.f_name.includes('Required!!') || data_rec.l_name.includes('Required!!') ? 'text-danger' : ''">{{data_rec.rec_type == 1 ? data_rec.f_name + ' ' + data_rec.l_name : data_rec.company_name}}</td>
                </tr>
                <tr v-if="data_rec.rec_type == 2">
                  <th scope="row">Full Name</th>
                  <td :class="data_rec.company_name.includes('Required!!') ? 'text-danger' : ''">{{data_rec.rec_type == 1 ? data_rec.f_name + ' ' + data_rec.l_name : data_rec.company_name}}</td>
                </tr>
                <tr v-if="data_rec.des_country">
                  <th scope="row">Destination Country</th>
                  <td :class="data_rec.des_country.includes('Required!!') ? 'text-danger' : ''">{{data_rec.des_country}}</td>
                </tr>
                <tr v-if="data_rec.acc_number">
                  <th scope="row">Account Number</th>
                  <td :class="data_rec.acc_number.includes('Required!!') ? 'text-danger' : ''">{{data_rec.acc_number}}</td>
                </tr>
                <tr v-if="data_rec.phone_n">
                  <th scope="row">Phone Number</th>
                  <td :class="data_rec.phone_n.includes('Required!!') ? 'text-danger' : ''">{{data_rec.phone_n}}</td>
                </tr>
                <tr v-if="data_rec.iban">
                  <th scope="row">IBAN</th>
                  <td :class="data_rec.iban.includes('Required!!') ? 'text-danger' : ''">{{data_rec.iban}}</td>
                </tr>
                <tr v-if="data_rec.bank_name">
                  <th scope="row">Bank Name</th>
                  <td :class="data_rec.bank_name.includes('Required!!') || data_rec.usd_bank_name.includes('Required!!') ? 'text-danger' : ''">{{data_rec.usd_bank_name ? data_rec.usd_bank_name + ' (' + data_rec.bank_name.split("-")[1]+ ')' : data_rec.bank_name ? data_rec.bank_name.split("-")[1] : '-'}}</td>
                </tr>
                <tr v-if="data_rec.swift">
                  <th scope="row">Swift Code</th>
                  <td  :class="data_rec.swift.includes('Required!!') ? 'text-danger' : ''">{{data_rec.swift}}</td>
                </tr>
                <tr v-if="data_rec.ifsc">
                  <th scope="row">IFSC</th>
                  <td  :class="data_rec.ifsc.includes('Required!!') ? 'text-danger' : ''">{{data_rec.ifsc}}</td>
                </tr>
                <tr v-if="data_rec.bsb_code">
                  <th scope="row">BSB Number</th>
                  <td  :class="data_rec.des_bsb_codecountry.includes('Required!!') ? 'text-danger' : ''">{{data_rec.bsb_code}}</td>
                </tr>
                <tr v-if="data_rec.bik_code">
                  <th scope="row">BIK Code</th>
                  <td  :class="data_rec.bik_code.includes('Required!!') ? 'text-danger' : ''">{{data_rec.bik_code}}</td>
                </tr>
                <tr v-if="data_rec.sort_code">
                  <th scope="row">Sort Code</th>
                  <td  :class="data_rec.sort_code.includes('Required!!') ? 'text-danger' : ''">{{data_rec.sort_code}}</td>
                </tr>
                <tr v-if="data_rec.transit_code">
                  <th scope="row">Transit Code</th>
                  <td  :class="data_rec.transit_code.includes('Required!!') ? 'text-danger' : ''">{{data_rec.transit_code}}</td>
                </tr>
                <tr v-if="data_rec.branch_name">
                  <th scope="row">Branch Name</th>
                  <td  :class="data_rec.branch_name.includes('Required!!') ? 'text-danger' : ''">{{data_rec.branch_name}}</td>
                </tr>
                <tr v-if="data_rec.branch_code">
                  <th scope="row">Branch Code</th>
                  <td  :class="data_rec.branch_code.includes('Required!!') ? 'text-danger' : ''">{{data_rec.branch_code}}</td>
                </tr>
                <tr v-if="data_rec.bank_add">
                  <th scope="row">Bank Address</th>
                  <td  :class="data_rec.bank_add.includes('Required!!') ? 'text-danger' : ''">{{data_rec.bank_add}}</td>
                </tr>
                <tr v-if="data_rec.address">
                  <th scope="row">Address</th>
                  <td  :class="data_rec.address.includes('Required!!') ? 'text-danger' : ''">{{data_rec.address}}</td>
                </tr>                
                <tr v-if="data_rec.city">
                  <th scope="row">City</th>
                  <td  :class="data_rec.city.includes('Required!!') ? 'text-danger' : ''">{{data_rec.city}}</td>
                </tr>
                <tr v-if="data_rec.province_name">
                  <th scope="row">Province</th>
                  <td  :class="data_rec.province_name.includes('Required!!') ? 'text-danger' : ''">{{data_rec.province_name}}</td>
                </tr>
                <tr v-if="data_rec.postalcode">
                  <th scope="row">Postal Code</th>
                  <td  :class="data_rec.postalcode.includes('Required!!') ? 'text-danger' : ''">{{data_rec.postalcode}}</td>
                </tr>
                <tr v-if="data_rec.email">
                  <th scope="row">Email</th>
                  <td  :class="data_rec.email.includes('Required!!') ? 'text-danger' : ''">{{data_rec.email}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </template>
      </form>
    </b-modal>
    <b-modal
      id="modal-1"
      v-model="showmodal4"
      title="Invoice"
      title-class="text-dark font-18"
      hide-footer
      scrollable
      centered
      size="xl"
    >
      <form>
        <template>
          <div>
            <a :href="screenshotview" class="btn btn-info" target="_blank" v-if="screenshotview.slice((screenshotview.lastIndexOf('.') - 1 >>> 0) + 2) == 'pdf'">View PDF</a>
            <img :src="screenshotview" alt="" v-else>
          </div>
        </template>
      </form>
    </b-modal>
    <!-- end modal -->
  </Layout>
</template>
<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.dropZone {
  width: 100%;
  height: 200px;
  position: relative;
  border: 2px dashed #eee;
}

.dropZone:hover {
  border: 2px solid #2e94c4;
}

.dropZone:hover .dropZone-title {
  color: #1975A0;
}

.dropZone-info {
  color: #A8A8A8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.dropZone-title {
  color: #787878;
}

.dropZone input {
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.dropZone-upload-limit-info {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.dropZone-over {
  background: #7ac0f2;
  opacity: 0.8;
}

.dropZone-uploaded {
  width: 100%;
  height: 200px;
  position: relative;
  border: 2px dashed #eee;
}

.dropZone-uploaded-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #a8a8a8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

a.disabled {
  pointer-events: none;
}
</style>
